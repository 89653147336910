import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { overview } from "./reducers/overview";
import { authReducer } from "./reducers/auth";
import {meta} from './reducers/meta';

const rootReducer = combineReducers({
  overview,
  auth: authReducer,
  meta:meta
});

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};
