import React, { Component } from 'react';
import { Button, Row, Col, Card, CardHeader, CardBody, CardTitle, Spinner } from 'reactstrap';

import Navbar from '../components/Navbar';
import { Line } from 'react-chartjs-2';

import '../styles/customStyles.css';
import { ErrorAlert } from '../components/Alerts/ErrorAlert';

export default class Overview extends Component {
	state = {
		current_page: 0,
		data: [],
		cms_data: [],
	};

	componentDidMount() {
		let token = localStorage.getItem('x-auth');

		if (token || this.props.auth.userToken) {
			this.props.history.push('/');
		} else {
			this.props.history.push('/login');
		}
	}

	async componentDidUpdate(prevProps) {
		if (this.props.auth !== prevProps.auth) {
			if (!this.props.auth.userToken) {
				this.props.history.push('/login');
			}
		}
	}

	render() {
		return (
			<div className="wrapper">
				<Navbar history={this.props.history} />
				{this.props.loading ? (
					<div
						className="content"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							backgroundColor: '#19191A',
						}}
					>
						<Spinner style={{ alignSelf: 'center' }} />
					</div>
				) : (
					<div
						className="content"
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Row className="mt-5">
							<Col lg="12">
								<Row
									style={{
										display: 'flex',
										alignItems: 'stretch',
										justifyContent: 'space-around',
									}}
								>
									<Col lg="2" md="4" sm="8" xs="12">
										<Card className="card-stats card_bg" style={{ backgroundColor: '#222223' }}>
											<CardBody>
												<Row>
													<Col lg="5" md="5" sm="5" xs="5">
														<div className="info-icon text-center icon-warning">
															<i className="tim-icons icon-user-run" />
														</div>
													</Col>
													<Col lg="7" md="7" sm="7" xs="7">
														<div className="numbers">
															<p className="card-category" style={{ color: '#fff' }}>
																Overall Progress
															</p>
															<CardTitle tag="h3">
																{this.props.masterData.overall_progress} %
															</CardTitle>
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
									<Col lg="2" md="4" sm="8" xs="12">
										<Card className="card-stats card_bg" style={{ backgroundColor: '#222223' }}>
											<CardBody>
												<Row>
													<Col lg="5" md="5" sm="5" xs="5">
														<div className="info-icon text-center icon-primary">
															<i className="fa fa-car" aria-hidden="true"></i>
														</div>
													</Col>
													<Col lg="7" md="7" sm="7" xs="7">
														<div className="numbers">
															<p className="card-category" style={{ color: '#fff' }}>
																Models Completed
															</p>
															<CardTitle tag="h3">
																{this.props.masterData.completed_models_count}
															</CardTitle>
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
									<Col lg="2" md="4" sm="8" xs="12">
										<Card className="card-stats card_bg" style={{ backgroundColor: '#222223' }}>
											<CardBody>
												<Row>
													<Col lg="5" md="5" sm="5" xs="5">
														<div className="info-icon text-center icon-success">
															<i className="tim-icons icon-check-2" />
														</div>
													</Col>
													<Col lg="7" md="7" sm="7" xs="7">
														<div className="numbers">
															<p className="card-category" style={{ color: '#fff' }}>
																Variants Completed
															</p>
															<CardTitle tag="h3">
																{this.props.masterData.completed_variants_count}
															</CardTitle>
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
									<Col lg="2" md="4" sm="8" xs="12">
										<Card className="card-stats card_bg" style={{ backgroundColor: '#222223' }}>
											<CardBody>
												<Row>
													<Col lg="5" md="5" sm="5" xs="5">
														<div className="info-icon text-center icon-danger">
															<i className="tim-icons icon-image-02" />
														</div>
													</Col>
													<Col lg="7" md="7" sm="7" xs="7">
														<div className="numbers">
															<p className="card-category" style={{ color: '#fff' }}>
																Features Completed
															</p>
															<CardTitle tag="h3">
																{this.props.masterData.completed_features_count}
															</CardTitle>
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
									<Col lg="2" md="4" sm="8" xs="12">
										<Card className="card-stats card_bg" style={{ backgroundColor: '#222223' }}>
											<CardBody>
												<Row>
													<Col lg="5" md="5" sm="5" xs="5">
														<div className="info-icon  text-center icon-info">
															<i className="fa fa-clock" />
														</div>
													</Col>
													<Col lg="7" md="7" sm="7" xs="7">
														<div className="numbers">
															<p className="card-category" style={{ color: '#fff' }}>
																Pending Approvals
															</p>
															<CardTitle tag="h3">
																{this.props.masterData.pending_approval_count}
															</CardTitle>
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Row>
									<Card className="card-chart card_bg" style={{ backgroundColor: '#222223' }}>
										<CardHeader>
											<Row>
												<Col className="text-left">
													<h5
														className="card-category"
														style={{ color: '#fff', fontWeight: '600' }}
													>
														Features
													</h5>
													<CardTitle tag="h2" style={{ color: '#fff', fontWeight: '500' }}>
														By Date
													</CardTitle>
												</Col>
											</Row>
										</CardHeader>
										<CardBody>
											<div className="chart-area">
												<Line
													data={this.props.masterData.overall_trend.data}
													options={this.props.masterData.overall_trend.options}
												/>
											</div>
										</CardBody>
									</Card>
								</Row>
							</Col>
						</Row>
					</div>
				)}
				{this.props.error && (
					<ErrorAlert message={this.props.error_msg} hide={() => this.props.dismissError()} />
				)}
			</div>
		);
	}
}
