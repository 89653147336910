const axios = require("axios");
const API_URL = process.env.REACT_APP_URL;

const headers = {
  "x-device-id": "analytics.device",
  "x-key": process.env.REACT_APP_API_KEY,
  "x-platform": 1,
  "x-origin": 3,
  "x-version": 1,
};

export const requestApi = async (resourcePath, method, params) => {
  const url = API_URL + resourcePath;
  headers["x-auth"] = JSON.parse(await localStorage.getItem("x-auth"));

  if (resourcePath == "/auths/login" || resourcePath == "/updates") {
    delete headers["x-auth"];
  }

  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    let response = await axios({ url, method, data: params, headers });
    return response.data;
  } else {
    let response = await axios({ url, method, headers });
    return response.data;
  }
};
