import React from "react";
import Overview from "../views/Overview";
import { connect } from "react-redux";
import { fetchMetaData } from "../store/actions/meta";
import {
  fetchOverviewData,
  dismissAnalyticsError,
} from "../store/actions/overview";

const OverviewContainer = (props) => {
  React.useEffect(() => {
    props.fetchOverviewData();
  }, []);

  return <Overview {...props} />;
};

const mapStateToProps = (state) => ({
  masterData: state.overview.data,
  loading: state.overview.loading,
  auth: state.auth,
  error: state.overview.error,
  error_msg: state.overview.error_msg,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOverviewData: () => dispatch(fetchOverviewData()),
    //dismissError: () => dispatch(dismissAnalyticsError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewContainer);
