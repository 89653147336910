import * as actionTypes from "../action_types/analytics";
import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";
import { returnOverview } from "../../utils/overview";
let chart_1_2_3_options = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    labels: {
      fontColor: "#fff",
    },
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#fff",
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#fff",
        },
      },
    ],
  },
};

export const fetchOverviewDataStart = () => {
  return {
    type: actionTypes.FETCH_OVERVIEW_ANALYTICS_START,
  };
};

export const fetchOverviewData = () => {
  return async (dispatch) => {
    dispatch(fetchOverviewDataStart());

    const response = await requestApi("/analytics/cms/overview", "GET", {});
    
    if (response.code == "success") {
      let completed_models_count = 
        response.data.analytics.content_completed_models_count;
      let completed_variants_count = response.data.analytics.content_completed_variants_count;
      let completed_features_count = response.data.analytics.content_completed_features_count;
      let pending_approval_count = response.data.analytics.content_pending_approval_count;
      let overall_progress = response.data.analytics.content_overall_progress.progress;

      let overall_trend = response.data.analytics.content_by_date;
      
      let unique_dates = Array.from(new Set(overall_trend)).map(
        (m) => m.date.split("T")[0]
      );
      
      let overall_trend_v2 = returnOverview(unique_dates, overall_trend);
      
      let overall_trend_data = {
        data: (canvas) => {
          let ctx = canvas.getContext("2d");

          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

          gradientStroke.addColorStop(1, "rgba(29,230,29,0.2)");
          gradientStroke.addColorStop(0.4, "rgba(29,230,29,0.0)");
          gradientStroke.addColorStop(0, "rgba(29,230,29,0)");

          return {
            // labels: unique_dates,
            labels: overall_trend_v2.map((m) => m.date),
            datasets: [
              {
                label: "Count",
                fill: true,
                backgroundColor: gradientStroke,
                hoverBackgroundColor: gradientStroke,
                borderColor: "#46b20c",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: overall_trend_v2.map((m) => m.count),
              },
            ],
          };
        },
        options: chart_1_2_3_options,
      };
      // const response = await requestApi("/analytics", "GET", {});
      // const trainings = response.data.training;
      let master_data = {};

      // master_data.model_count_data = trainings.count_by_models;
      // master_data.location_count_data = trainings.count_by_locations;
      // master_data.parts_count_data = trainings.count_by_parts;
      // master_data.users_count_data = trainings.count_by_users;
      // master_data.variants_count_data = trainings.count_by_variants;

      master_data.overall_progress = overall_progress;
      master_data.completed_models_count = completed_models_count.count;
      master_data.completed_variants_count = completed_variants_count.count;
      master_data.completed_features_count = completed_features_count.count;
      master_data.pending_approval_count = pending_approval_count.count;
      master_data.overall_trend = overall_trend_data;
     
      return dispatch({
        type: actionTypes.FETCH_OVERVIEW_ANALYTICS_SUCCESS,
        payload: master_data,
      });
    } else if (response.code == "authn_fail") {
      return dispatch(signOut());
    } else {
      return dispatch(fetchOverviewAnalyticsFailure(response.message));
    }
  };
};

export const fetchOverviewAnalyticsFailure = (msg) => {
  return {
    type: actionTypes.FETCH_OVERVIEW_ANALYTICS_FAILURE,
    payload: msg,
  };
};

export const dismissAnalyticsError = () => {
  return {
    type: "DISMISS_ERROR_OVERVIEW_ANALYTICS",
  };
};
