export const returnOverview = (uniqueDates, data) => {
	
	let dates = [];
	if (uniqueDates.length > 0) {
		let dateRangeStart = new Date(uniqueDates[0]);
		let dateRangeEnd = new Date(uniqueDates[uniqueDates.length - 1]);
		let _date = new Date(dateRangeStart);
		Date.prototype.addDays = function (days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};

		if (dateRangeStart.toISOString() == dateRangeEnd.toISOString()) {
			dates.push(dateRangeStart.toISOString());
		} else {
			while (_date <= dateRangeEnd) {
				dates.push(_date.toISOString());
				_date = _date.addDays(1);
			}
		}
	} else {
		dates = [];
	}
 
	// Now use .find() to match the date. if count is there, use it, else put 0

	let modelMap = [];

	dates.map((d) => {
		let obj = {};
		obj.date = d.substring(0, 10);
		let find_data = data.find((o) => d === o.date);
		obj.count = find_data ? find_data.count : 0;
		modelMap.push(obj);
	});
	return modelMap;
};
