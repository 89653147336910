import * as action_types from "../action_types/analytics";
const initialState = {
  loading: true,
  data: {},
  bar_data: {},
  error: false,
  error_msg: null,
};

export const overview = (state = initialState, action) => {
  switch (action.type) {
    case action_types.FETCH_OVERVIEW_ANALYTICS_START:
      return {
        ...state,
        loading: true,
      };

    case action_types.FETCH_OVERVIEW_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case action_types.FETCH_OVERVIEW_ANALYTICS_FAILURE:
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loading:false,
        
      };
    case "DISMISS_ERROR_OVERVIEW_ANALYTICS":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    default:
      return state;
  }
};