/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// reactstrap components
import {
	Button,
	Collapse,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Input,
	InputGroup,
	NavbarBrand,
	Navbar,
	NavLink,
	Nav,
	Container,
	Modal,
	UncontrolledTooltip,
} from 'reactstrap';
import Xane_A from '../assets/img/xane-icon.png';
import Suzuki_S from '../assets/img/suzuki_s.png';

import { connect } from 'react-redux';
import { signOut } from '../store/actions/auth';

class AdminNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseOpen: false,
			modalSearch: false,
			color: 'navbar-transparent',
		};
	}
	componentDidMount() {
		window.addEventListener('resize', this.updateColor);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateColor);
	}
	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	updateColor = () => {
		if (window.innerWidth < 993 && this.state.collapseOpen) {
			this.setState({
				color: 'bg-white',
			});
		} else {
			this.setState({
				color: 'navbar-transparent',
			});
		}
	};
	// this function opens and closes the collapse on small devices
	toggleCollapse = () => {
		if (this.state.collapseOpen) {
			this.setState({
				color: 'navbar-transparent',
			});
		} else {
			this.setState({
				color: 'bg-white',
			});
		}
		this.setState({
			collapseOpen: !this.state.collapseOpen,
		});
	};
	// this function is to open the Search modal
	toggleModalSearch = () => {
		this.setState({
			modalSearch: !this.state.modalSearch,
		});
	};
	render() {
		const { pathname } = this.props.history.location;
		return (
			<>
				<Navbar expand="lg" style={{ backgroundColor: '#303030' }}>
					<Container fluid style={{ display: 'flex' }}>
						<div className="navbar-wrapper">
							<NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
								<p style={{ fontWeight: 'bold' }}>
									{window.screen.width > 450 && <img src={Suzuki_S} width={24} />}{' '}
									<span style={{ marginLeft: 5 }}>CONTENT MANAGEMENT SYSTEM ANALYTICS</span>
								</p>
							</NavbarBrand>
						</div>
						<div>
							<a href="#" onClick={() => this.props.signOut()}>
								<i
									className="fa fa-power-off fa-lg"
									aria-hidden="true"
									style={{ color: '#FF3300', marginRight: 5 }}
								></i>
							</a>{' '}

						</div>
					</Container>
				</Navbar>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	signOut: () => dispatch(signOut()),
});

export default connect(null, mapDispatchToProps)(AdminNavbar);
